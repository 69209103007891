<template>
  <van-form @submit="onSubmit">
    <van-cell-group title=" " inset>
      <van-field name="authenticationType" label="认证类型">
        <template #input>
          <van-radio-group v-model="row.authenticationType" direction="horizontal">
            <van-radio name="INDIVIDUAL">个人 </van-radio>
            <van-radio name="BUSINESS">企业</van-radio>
          </van-radio-group>
        </template>
      </van-field>
      <van-field v-model="row.authenticationName" name="authenticationName" label="主体名称" placeholder="个人姓名或公司名称" />
      <van-field name="authenticationAttachments" label="证件照片">
        <template #input>
          <van-uploader
          v-model="row.authenticationAttachments"
          :max-count="5"
          :max-size="10 * 1024 * 1024"
          :after-read="afterRead"
          />
        </template>
      </van-field>
    </van-cell-group>
    <div style="margin: 16px;">
      <template v-if="row.authenticationStatus === 'UNCERTIFIED'">
        <van-button round block type="primary" native-type="submit">
          开始认证
        </van-button>
      </template>
      <template v-else-if="row.authenticationStatus === 'VERIFYING'">
        <van-button round block type="primary" native-type="submit" disabled>
          {{ row.authenticationStatusText }}
        </van-button>
      </template>
      <template v-else-if="row.authenticationStatus === 'CERTIFIED'">
        <van-button round block type="primary" native-type="submit">
          重新认证
        </van-button>
      </template>
    </div>
  </van-form>
  <div style="margin:15px 0 0 0;background:#fff;padding:15px;line-height:1.8;">
    <p><small>1. 个人类型，填写信息须与店铺注册手机号主人一致</small></p>
    <p><small>2. 个人类型，请上传身份证正面与反面，2张照片</small></p>
    <p><small>3. 企业类型，请上传营业执照，1张照片</small></p>
    <p><small>4. 认证后，可使用全部模块与功能</small></p>
    <p><small>5. 若主体信息有变更，请及时重新认证</small></p>
  </div>
</template>

<script>

import { toRefs, reactive, onMounted, inject } from 'vue'
import { Toast } from 'vant'
import { upload } from '@/util/util'

export default {
  props: {
  },
  setup () {
    const post = inject('post')
    const Cookies = inject('Cookies')
    const state = reactive({
      password: '',
      qiniu: {},
      row: {},
      storeId: Cookies.get('storeId')
    })
    const onSubmit = (values) => {
      Toast.loading({ forbidClick: true, duration: 0 })
      post('/store.applyAuthentication', {
        storeId: state.storeId,
        ...values,
        authenticationAttachments: values.authenticationAttachments.length > 0 ? values.authenticationAttachments.filter(v => (typeof v.url !== 'undefined') && v.url !== null).map(v => { return v.url }) : []
      }).then(res => {
        Toast.clear()
        if (res.code === 0) {
          Toast.success()
          init()
        } else {
          Toast(res.msg)
        }
      })
    }
    const afterRead = (file) => {
      upload({
        fileBlob: file.file,
        token: state.qiniu.token,
        domain: state.qiniu.domain
      }).then(res => {
        file.url = res.img
        console.log(res.img)
      })
    }
    const init = () => {
      post('/store.get', {
        storeId: state.storeId
      }).then(res => {
        state.row = Object.assign({}, res.data)
        state.row.authenticationAttachments = []
        for (const item of res.data.authenticationAttachments) {
          state.row.authenticationAttachments.push({
            url: item,
            isImage: true
          })
        }
      })
      post('/util.getQiniuToken', {
      }).then(res => {
        state.qiniu = res.data
      })
    }
    onMounted(() => {
      init()
    })
    return {
      ...toRefs(state),
      onSubmit,
      afterRead
    }
  }
}
</script>

<style scoped>
</style>
